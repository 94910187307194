import { Container, Stack, Typography } from '@mui/material';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { PartnerProps } from 'src/@types/partner';
import NextSanityImage from 'src/components/NextSanityImage';
import { A11y, Autoplay, FreeMode } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

// ----------------------------------------------------------------------

const RootStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 0),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  partners: PartnerProps[];
};

export default function Partners({ partners }: Props) {
  const theme = useTheme();

  return (
    <RootStyle>
      <Container>
        <Stack
          spacing={3}
          sx={{ mb: 8, mx: 'auto', maxWidth: 480, textAlign: 'center' }}
        >
          <Typography variant="h2">Samarbeidspartnere</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sammen med våre høyt verdsatte samarbeidspartnere sørger vi for at din opplevelse blir
            så bra som mulig.
          </Typography>

        </Stack>
        <Swiper
          modules={[Autoplay, A11y, FreeMode]}
          speed={1000}
          spaceBetween={100}
          slidesPerView={2}
          loop
          breakpoints={{
            [theme.breakpoints.values.md]: {
              slidesPerView: 6,
              spaceBetween: 50
            },
            [theme.breakpoints.values.sm]: {
              slidesPerView: 4,
              spaceBetween: 70
            }
          }}
          autoplay={{
            delay: 500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          {partners.map((brand) => (
            <SwiperSlide key={brand.name}>
              <Box
                rel="noreferrer nofollow"
                component="a"
                target="blank"
                href={brand.URL}
              >
                <NextSanityImage alt={brand.name + " logo"} height={30} width={200} layout="intrinsic" ratio="16/9" contain src={brand.logo} sx={{ filter: 'brightness(0.5) grayscale(1)' }} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>

      </Container>
    </RootStyle>
  );
}
